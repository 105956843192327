import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Row, Col, Form } from 'react-bootstrap';
import * as styles from './form.module.scss';
import { RightArrowIcon } from '../../images/index';
import showdown from 'showdown';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from 'react-html-parser';

const ContactForm = ({ privacy }) => {
  const converter = new showdown.Converter();
  const privacyHtml = converter
    .makeHtml(privacy)
    .replace('<p>', '')
    .replace('</p>', '');
  const intl = useIntl();

  return (
    <Form
      name="Contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="Contact" />
      <p hidden>
        <label>
          Don’t fill this out if you’re human: <input name="bot-field" />
        </label>
      </p>
      <Form.Group className="mb-3" controlId="controlInput1">
        <Form.Label>Email*</Form.Label>
        <Form.Control
          required
          type="email"
          name="email"
          placeholder="example@email.com"
          className={styles.inputField}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="controlTextarea1">
        <Form.Label>
          {intl.formatMessage({ id: 'main.form.message' })}*
        </Form.Label>
        <Form.Control
          required
          as="textarea"
          name="message"
          rows={5}
          placeholder={intl.formatMessage({ id: 'main.form.placeholder' })}
          className={styles.inputField}
        />
      </Form.Group>
      <Row>
        <Form.Check
          id="checkbox1"
          value="Feliratkozom"
          name="Newsletter"
          type="checkbox"
          label={intl.formatMessage({ id: 'main.form.newsletter' })}
          className={styles.checkbox}
        />
        <Form.Check
          required
          id="checkbox2"
          type="checkbox"
          label={<>{ReactHtmlParser(privacyHtml)}</>}
          className={styles.checkbox}
        />
      </Row>
      <Row>
        <Col className={styles.buttonColumn}>
          <button type="submit" className={styles.button}>
            {intl.formatMessage({ id: 'button.send' })} <RightArrowIcon />
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactForm;
