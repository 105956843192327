import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Col, Container, Row } from 'react-bootstrap';
import Form from '../ContactForm/Form';
import * as styles from './contact.module.scss';
import {
  FacebookCircleIcon,
  InstagramCircleIcon,
  LinkedinCircleIcon,
  PinterestCircleIcon,
  EnvelopeIcon
} from '../../images/index';

const Contact = ({ contact }) => {
  const image = contact.image;
  const privacy = contact.privacy;

  return (
    <Container>
      <Row className={styles.section}>
        <Col md={6}>
          <h3 className="title">{contact.title}</h3>
          <p className={styles.paragraph}>{contact.paragraph}</p>
          <p className={styles.email}>
            <a href={`mailto:${contact.email}`}>
              <EnvelopeIcon className={styles.icon} />
              {contact.email}
            </a>
          </p>
          <Form privacy={privacy} />
          <h3 className="title">{contact.social.title}</h3>
          <p>
            <a title="facebook" href={`${contact.social.facebook}`}>
              <FacebookCircleIcon className={styles.socialIcon} />
            </a>
            <a title="instagram" href={`${contact.social.instagram}`}>
              <InstagramCircleIcon className={styles.socialIcon} />
            </a>
            <a title="pinterest" href={`${contact.social.pinterest}`}>
              <PinterestCircleIcon className={styles.socialIcon} />
            </a>
            <a title="linkedin" href={`${contact.social.linkedin}`}>
              <LinkedinCircleIcon className={styles.socialIcon} />
            </a>
          </p>
        </Col>
        <Col md={6} className="text-center">
          <GatsbyImage
            image={getImage(image)}
            alt={contact.imageAlt}
            className="image"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
